import "./BookTripStyles.css"
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { format } from 'date-fns';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useState } from 'react';


export default function BookTripData(props){
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numberOfPeople, setNumberOfPeople] = useState(1);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const handleDateChange = (selectedDate) => {
        setSelectedDateTime(selectedDate);
        populateModal()
      };  

      const populateModal = () =>{
        const formattedDate = format(selectedDateTime, 'dd/MM/yyyy');
        //Format the time as "HH:mm" (24-hour format)
        const formattedTime = format(selectedDateTime, 'HH:mm');  
        //const formattedDateTimeForEmail = formattedDate + ' ' + formattedTime;
        return `tu reserva es para el ${formattedDate} a las ${formattedTime}`
	   // console.log(formattedDateTimeForEmail);
      }

      const handleReservation = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

      const handleConfirmation = async () => {
        // Gather the data to be sent
        const reservationDetails = {
          name: userName,
          email: email,
          phone: phone,
          date: selectedDateTime.toISOString(), // Format the date to ISO string
          numberOfPeople: numberOfPeople,
          // You can add any additional data you need to send
        };
      
        try {
          const response = await fetch('https://aedo-turismo-backend.onrender.com/send-email', { // Use the correct backend endpoint URL and port
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(reservationDetails),
          });
      
          const data = await response.json();
      
          if (response.ok) {
            // Handle success - maybe close the modal, clear the form, or show a success message
            console.log('Reservation confirmed:', data);
            closeModal();
          } else {
            // Handle response errors (e.g., 400 Bad Request)
            console.error('Reservation could not be confirmed:', data);
            // You can show an error message to the user here
          }
        } catch (error) {
          // Handle network errors - show error message to the user
          console.error('There was an error confirming the reservation:', error);
        }
      };
      


      const handleNumberOfPeopleChange = (value) => {
        if (value >= 1 && value <= 10) {
          setNumberOfPeople(value);
        }
      };
    
      const increment = () => {
        if (numberOfPeople < 10) {
          handleNumberOfPeopleChange(numberOfPeople + 1);
        }
      };
    
      const decrement = () => {
        if (numberOfPeople > 1) {
          handleNumberOfPeopleChange(numberOfPeople - 1);
        }
      };

    return (
        <div className={props.className}>
            <div className="book-t-image">
                <Carousel>
                    {
                    props.images.map(image => <img src={image} alt="" /> )
                    }
              {/*   <img src={props.image} alt="" />*/}
                </Carousel>
            </div>
            <div className="book-t-body">
                <h4>{props.heading}</h4>
                <p>{props.text}</p>
                <ul className="book-list-body">
                    <li>{props.price}</li>
                    <li>{props.capacity}</li>
                    <li>{props.schedule}</li>
                </ul>
                <DateTimePicker
                    selected={selectedDateTime}
                    value={selectedDateTime}
                    onChange={handleDateChange}
                    showTimeSelect
                    timeInputLabel="Time"
                    dateFormat="Pp"
                />
                <button onClick={handleReservation} className="button">{props.button}</button>

                {isModalOpen && (
                <div className="modal">

                
                    <div className="modal-content">

                    <span className="close" onClick={closeModal}>&times;</span>
                    <h2>Reservation Details</h2>
                    <p>Please provide your contact information so that we may reach you once your reservation for <strong>{props.heading}</strong> is confirmed</p>
                    <p>{populateModal()}</p>
                    <input
                        className="modal_input"
                        type="text"
                        placeholder="name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <input
                        className="modal_input"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        className="modal_input"
                        type="text"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <p>cantidad de personas</p>

                    <div className="modal_number_input">  
                    <button className="number_button" onClick={decrement}>-</button>
                    <input
                        className="input_button"
                        type="number"
                        placeholder="Number of people"
                        value={numberOfPeople}
                        onChange={(e) => handleNumberOfPeopleChange(e.target.value)}
                        min="1"
                        max="10"
                    />
                    <button className="number_button" onClick={increment}>+</button>
                    </div>
                    
                    <button onClick={handleConfirmation}  className="button"  id={props.id}>Confirm Reservation</button>
                    </div>
                </div>
                )}
            </div>
        </div>
    )
}