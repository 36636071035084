import "./TripStyles.css"
import {Link} from "react-router-dom";


export default function TripData(props){
    return (
        <div className="t-card">
            <div className="t-image">
                <img src={props.image} alt="" />
            </div>
                <h4>{props.heading}</h4>
                <p>{props.text}</p>
                <Link to={props.link}>
                <button>{props.button}</button>
                </Link>
        </div>
    )
}