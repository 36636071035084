import { Hero } from '../components/Hero'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ServicesImg from '../assets/Boat.jpg'
import BookTrip from '../components/BookTrip'
import BookOtherServices from '../components/BookOtherServices'
import '../i18n'; // Import your i18n configuration
import { useTranslation } from 'react-i18next';


export function Services(){
  const { t } = useTranslation();
    return(
        <>
          <Navbar/>
            <Hero
            cName="hero-mid"
            heroImg={ServicesImg}
            title={t('services-title')}
            btnClass="hide"
            />
            <BookTrip/>
            <BookOtherServices/>
             <Footer/>
        </>
    )
}
