import DestinationData from './DestinationData';
import './DestinationStyles.css';
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";

import DestinationImg1 from '../assets/volcan-villarrica.jpeg' 
import DestinationImg2 from '../assets/volcan-villarrica-barco-vista.jpeg' 
import DestinationImg3 from '../assets/boatSunriseLakeVillarrica.jpg'
import DestinationImg4 from '../assets/wildlife-villarrica-lake.jpg'
import DestinationImg5 from '../assets/vertical/ducklings.jpg'
import DestinationImg6 from '../assets/vertical/nativeFlowersChile.jpg'


const Destination = () =>{
    const { t } = useTranslation();

    return (
        <div className="destination">
            <h1>{t('destination-h1-popular-destinations')}</h1>
            <p>{t('destination-paragraph')}</p>
        <DestinationData
        cName="first-des"
        heading={t('destination-item-1-heading')}
        text={t('destination-item-1-text')}
     
        img1={DestinationImg1}
        img2={DestinationImg2}
      
       />

        <DestinationData
        cName="first-des-reverse"
        heading={t('destination-item-2-heading')}
        text={t('destination-item-2-text')}
        img1={DestinationImg3}
        img2={DestinationImg4}
        />

<DestinationData
        cName="first-des"
        heading={t('destination-item-3-heading')}
        text={t('destination-item-3-text')}
        img1={DestinationImg5}
        img2={DestinationImg6}
        />

        </div>
    );
};

export default Destination 