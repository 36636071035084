import { createContext, useState } from 'react';

const LocaleContext = createContext();

export default LocaleContext;

export function LocaleProvider({ children }) {
  // Initialize your locale state using useState
  const [locale, setLocale] = useState('es'); // Set an initial locale value

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
}
