import { Hero } from '../components/Hero'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ContactImg from '../assets/blisteringSun.jpg'
import ContactForm from '../components/ContactForm'
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";

export function Contact(){
  const { t } = useTranslation();
    return(
        <>
          <Navbar/>
            <Hero
            cName="hero-mid"
            heroImg={ContactImg}
            title={t('inquiry-title')}
            btnClass="hide"
            />
            <ContactForm/>
             <Footer/>
        </>
    )
}