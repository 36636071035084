//import i18n from '../i18n';  
//import { useTranslation } from "react-i18next";
//import LocaleContext from '../context/LocaleContext.jsx'

/*
const { t } = useTranslation();
const { locale, setLocale } = useContext(LocaleContext) 
i18n.on('languageChanged', (lng) => setLocale(i18n.language));
*/

export const MenuItems = [
    {
        key : 'navbar-home',
        url : "/",
        cName : "nav-links",
        icon : "fa-sharp fa-solid fa-house-chimney"

    },
    {
        key : 'navbar-about',
        url : "/about",
        cName : "nav-links",
        icon : "fa-sharp fa-solid fa-circle-info"
   
    },
    {
        key : 'navbar-services',
        url : "/services",
        cName : "nav-links",
        icon : "fa-sharp fa-solid fa-ship"
   
    },
    {
        key : 'navbar-contact',
        url : "/inquiries",
        cName : "nav-links",
        icon : "fa-sharp fa-solid fa-address-book"
   
    },

    
]