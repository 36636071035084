// ContactForm.js
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "./ContactFormStyles.css";
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";

export default function ContactForm() {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const inquiryData = { name, email, subject, message };

        try {
            const response = await fetch('https://aedo-turismo-backend.onrender.com/send-inquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(inquiryData),
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Inquiry sent:', data);
                navigate('/'); // Redirect to homepage
            } else {
                console.error('Inquiry could not be sent:', data);
                // Handle error
            }
        } catch (error) {
            console.error('There was an error sending the inquiry:', error);
            // Handle error
        }
    };
    return (
        <div className="form-container">
            <h1>{t('inquiry-h1')}</h1>
            <form action="">
                <input placeholder={t('inquiry-placeholder-name')} type="text" />
                <input placeholder={t('inquiry-placeholder-email')} type="email" />
                <input placeholder={t('inquiry-placeholder-subject')} type="text" />
                <textarea placeholder={t('inquiry-placeholder-message')} name="message" cols="30" rows="7"></textarea>
                <button>{t('inquiry-button-send')}</button>
            </form>
        </div>
    
    );
}
