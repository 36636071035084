import { useContext, useState} from "react";
//import { Component, useState } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems"; 
import {Link} from "react-router-dom";
import Logo from '../assets/LOGO.png'
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import LocaleContext from '../context/LocaleContext.jsx'
//import { click } from "@testing-library/user-event/dist/click";

// name of X icon = fas fa-times
export default function Navbar(){
    
    const { t } = useTranslation();
    const { locale, setLocale } = useContext(LocaleContext) 
    i18n.on('languageChanged', (lng) => setLocale(i18n.language));
    
    const [toggle, setToggle] = useState(false);
    
    const handleClick = ()=>{
        setToggle(prevState=> !prevState)

    }

        return(
            <nav className="navbarItems">
                <h1 className="navbar-logo"> <img src={Logo} alt="" /></h1>
                <div className="language-selection">
                    <label className="language-label">{t('language-label')}</label>
                    <Select
                        className="language-select"
                        options={[
                        { value: 'en', label: t('english') },
                        { value: 'es', label: t('spanish') },
                        ]}
                        value={{ value: locale, label: locale === 'en' ? t('english') : t('spanish') }}
                        onChange={(selectedOption) => {
                        i18n.changeLanguage(selectedOption.value);
                        setLocale(selectedOption.value);
                        }}
                    />
                </div>

                <div className="menu-icons" onClick={handleClick}>
                    <i className={toggle? "fas fa-times" : "fas fa-bars" }></i>
                </div>

                <ul className={ toggle? "nav-menu active" : "nav-menu"}>
                    { MenuItems.map((item, index)=>{
                        return(
                            <li key={index} >
                                <Link className={item.cName} to={item.url}>
                                 <i className={item.icon}></i>
                                 {t(item.key)}
                                </Link>
                            </li>
                        )
                    })}
                    <Link to="/#trip-service-options">
                    <button className="signUp-button">{t('navbar-start-trip')}</button>
                    </Link>
                </ul>
            </nav>
        )
}

