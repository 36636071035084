import "./BookTripStyles.css"
import BookTripData from "./BookTripData"
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

// import useScrollToId from '../hooks/useScrollToId';

import fullLakeView from "../assets/trip-images/media-hora/fullLakeViewVillarrica.jpg"
import puenteLagoVillarrica from "../assets/trip-images/media-hora/puente-lago-villarrica.jpeg"
import vistaVolcanLagoIsla from "../assets/trip-images/media-hora/vista-volcan-lago-isla.jpeg"
import volcanVillarricaDesdeLago from "../assets/trip-images/media-hora/volcan-villarrica-desde-lago.jpeg"

import barco from "../assets/trip-images/una-hora/AEDOBoat.jpg"
import viewFullLake from "../assets/trip-images/una-hora/fullLakeViewVillarrica.jpg"
import volcanVillarricaExpulsandoHumo from "../assets/trip-images/una-hora/volcan-villarrica-expulsando-humo.jpeg"
import volcanVillarrica from "../assets/trip-images/una-hora/volcan-villarrica.jpeg"
import wildlifeVillarricaLake from "../assets/trip-images/una-hora/wildlife-villarrica-lake.jpg"

import barcoAtardecerPiesDelLago from "../assets/trip-images/cuatro-horas/barco-atardecer-pies-del-lago.jpeg"
import barcoFotoNoche from "../assets/trip-images/cuatro-horas/barco-foto-noche.jpeg"
import faroVolcanVillarrica from "../assets/trip-images/cuatro-horas/faro-volcan-villarrica.jpeg"
import islaAillaquillenFaroTuristasFelices from "../assets/trip-images/cuatro-horas/isla-aillaquillen-faro-turistas-felices.jpeg"
import islaAillaquillenTuristaFotoVistaVolcan from "../assets/trip-images/cuatro-horas/isla-aillaquillen-turista-sacando-foto-vista-volcan.jpeg"
import villarricaBirdView from "../assets/trip-images/cuatro-horas/villarrica-lake--island-birdview.jpg"
import volcanVillarricaDesdesIsla from "../assets/trip-images/cuatro-horas/volcan-villarrica-desdes-isla.jpeg"


const tripsThirtyMins = [fullLakeView, puenteLagoVillarrica,vistaVolcanLagoIsla,volcanVillarricaDesdeLago]
const tripsOneHour = [barco, viewFullLake, volcanVillarricaExpulsandoHumo,volcanVillarrica,wildlifeVillarricaLake ]
const tripsHalfDay = [barcoAtardecerPiesDelLago, barcoFotoNoche,faroVolcanVillarrica,islaAillaquillenFaroTuristasFelices,islaAillaquillenTuristaFotoVistaVolcan,villarricaBirdView,volcanVillarricaDesdesIsla ]

export default function BookTrip(){


    const { t } = useTranslation();
    return (
        <div className="book-trip">
            <h1>{t('booktrip-h1')}</h1>
            <p>{t('booktrip-p')}</p>
            <div className="book-trip-card">
                <BookTripData id="trip-services-lake"
                className="book-t-card"
                images={tripsThirtyMins}
                heading={t('booktrip-heading-1')}
                text={t('booktrip-text-1')}
                capacity={t('booktrip-capacity-1')}
                price ={t('booktrip-price-1')}
                button={t('booktrip-button-1')}
                />
                 <BookTripData id="trip-services-island"   
                className="book-t-card-reverse"
                images={tripsOneHour}
                heading={t('booktrip-heading-2')}
                text={t('booktrip-text-2')}
                capacity={t('booktrip-capacity-2')}
                schedule={t('booktrip-schedule-2')}
                price ={t('booktrip-price-2')}
                button={t('booktrip-button-2')}
                />
                 <BookTripData id="trip-services-halfday"
                 className="book-t-card"
                images={tripsHalfDay}
                heading={t('booktrip-heading-3')}
                text={t('booktrip-text-3')}
                capacity={t('booktrip-capacity-3')}
                schedule={t('booktrip-schedule-3')}
                price ={t('booktrip-price-3')}
                button={t('booktrip-button-3')}
                />

            </div>
        </div>
    )
}