import "./AboutUsStyles.css"
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";
import aboutUsImg from '../assets/AEDOBoat.jpg'

export default function AboutUs(){
    const { t } = useTranslation();
    return (
        <article className="about-container">
        <div className="about-section">
            <h1>{t('about-name-history-heading')}</h1>
            <div className="about-text">
            <p>{t('about-name-history-paragraph')}</p>
            </div>
        </div>
        <div className="about-section">
            <h1>{t('about-name-mission-heading')}</h1>
            <div className="about-text">
            <p>{t('about-name-mission-paragraph')}</p>
            </div>
        </div>
        <div className="about-section">
            <h1>{t('about-name-vision-heading')}</h1>
            <div className="about-text">
            <p>{t('about-name-vision-paragraph')}</p>
            </div>
        </div>
        </article>

    )
}