import './styles.css';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Home } from './routes/Home';
import { About } from './routes/About';
import {Services} from './routes/Services';
import { Contact } from './routes/Contact';
import { Gallery } from './routes/Gallery';
//import { Register } from './routes/Register';
//import { Schedule } from './routes/Schedule';

import LocaleContext, {LocaleProvider} from './context/LocaleContext.jsx'
import { Suspense, useContext, useState } from 'react';
import i18n from "../src/i18n"; 


function Loading(){
  return (
    <>Loading...</>
  )
}



function App() {
    const [locale, setLocale] = useState(i18n.language);


  return (
    <main className="App">
    <LocaleProvider value={{locale, setLocale}}>
     <Suspense fallback={<Loading/>}>
      <Router>
        <Routes>
           <Route path="/" element={<Home/>}/>
           <Route path="/about" element={<About/>}/>
           <Route path="/services" element={<Services/>}/>
           <Route path="/inquiries" element={<Contact/>}/>
           <Route path="/gallery" element={<Gallery/>}/>  
           {/* <Route path="/schedule" element={<Schedule/>}/> */}
           {/*<Route path="/register" element={<Register/>}/> */}
       </Routes>
      </Router>
      </Suspense>
    </LocaleProvider>
    </main>
  );
}

export default App;
