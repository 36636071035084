import "./TripStyles.css"
import TripData from "./TripData"
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";
import useScrollToId from '../hooks/useScrollToId';
import {Link} from "react-router-dom";
import Trip1 from "../assets/villarrica-full-lake-view.jpg"
import Trip2 from "../assets/villarrica-lake-birdview.jpg"
import Trip3 from "../assets/boatLakeSunset.jpg"

export default function Trip(){
    useScrollToId('trip-service-options');

const { t } = useTranslation();
    return (
        <div className="trip" id="trip-service-options">
            <h1>{t('trip-h1')}</h1>
            <p>{t('trip-p')}</p>
            <div className="trip-card">
                <TripData 
                image={Trip1}
                heading={t('trip-item-1-heading')}
                text={t('trip-item-1-text')}
                link={"/services#trip-services-lake"}
                
                button={t('trip-item-1-button')}
                />
                 <TripData
                image={Trip2}
                heading={t('trip-item-2-heading')}
                text={t('trip-item-2-text')}
                button={t('trip-item-2-button')}
                link={"/services#trip-services-island"}
                />
                 <TripData
                image={Trip3}
                heading={t('trip-item-3-heading')}
                text={t('trip-item-3-text')}
                button={t('trip-item-3-button')}
                link={"/services#trip-services-halfday"}
                />
            </div>
        </div>
    )
}