import "./FooterStyles.css"
import i18n from '../i18n';  
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";


const Footer = ()=>{
    const { t } = useTranslation();

    return (
        <div className="footer">
            <div className="top">
                <div>
                    <h1>Aedoturismo</h1>
                    <p>&nbsp;&nbsp;{t('footer-aedo-turismo-slogan')}</p>
                </div>
                <div>
                    <a href="https://www.facebook.com/Aedoturismo/">
                        <i className="fa-brands fa-facebook-square"></i>
                    </a>
                    <a href="https://www.instagram.com/aedoturismo/?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr">
                         <i className="fa-brands fa-instagram-square"></i>
                    </a>
                    <a href="https://wa.me/56983571240">
                        <i className="fa-brands fa-whatsapp-square"></i>
                    </a>

                </div>
            </div>
            <div className="bottom">
                <div>
                    <h4>{t('footer-services-h2')}</h4>
                    <a href="/services">{t('footer-services-boat-trip')}</a>
                    <a href="/services">{t('footer-services-kayak')}</a>
                    <a href="/services">{t('footer-services-bike')}</a>
                    
                    
                </div>
                <div>
                    <h4>{t('footer-services-h4-find-us')}</h4>
                    <a href="https://maps.app.goo.gl/PHBjD4P4kjZWD4dHA"> {t('footer-services-google-my-biz')}</a>
                    <a href="/https://www.google.com/maps/dir//Entretenciones+Acu%C3%A1ticas+Aedo+-+K%C3%B6rner+s%2Fn+Embarcadero+Municipal,+4930000+Villarrica,+Araucan%C3%ADa/@-39.2806504,-72.2211238,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x9614623b9b9ed447:0xbf794fa1ab563b90!2m2!1d-72.2211238!2d-39.2806504!3e0?entry=ttu">📍 {t('footer-services-location')}</a>

                </div>
                <div>
                    <h4>{t('footer-h4-contact')}</h4>
                    <a href="mailto:maedogutierrez@gmail.com">{t('footer-email')}</a>
                    <a href="tel:+56983571240">{t('footer-call')}</a>
                    <a href="/contact">{t('footer-inquiry')}</a>
                </div>
                <div>
                    <h4>{t('footer-other')}</h4>
                    <Link to={"/gallery"}><span className="gallery">📷 {t('footer-terms')}</span></Link>
                   {/*  <a href="/">{t('footer-policy')}</a> */}
            
                </div>
            </div>

        </div>
    )
}

export default Footer