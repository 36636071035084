import "./BookTripStyles.css"
import BookOtherServicesData from "./BookOtherServicesData"
import kayakTwo from "../assets/other-services/kayak-double.png"
import kayakOne from "../assets/other-services/kayak-single.png"
import bike from "../assets/other-services/bicycle.png"
import catamaran from "../assets/other-services/catamaran.png"
import '../i18n'; // Import your i18n configuration
import { useTranslation } from 'react-i18next';


export default function BookOtherServices(){
    const { t } = useTranslation();
    return (
        <div className="book-trip">
            <h1>{t('bookOtherServices-h1')}</h1>
            <p>
            {t('bookOtherServices-p2')}
            </p>
            <div className="book-trip-card">
                 <BookOtherServicesData
                 className="book-t-card-reverse"
                image={catamaran}
                heading={t('bookOtherServices-heading-1')}
                text={t('bookOtherServices-text-1')}
                capacity={t('bookOtherServices-capacity-1')}
                price={t('bookOtherServices-price-1')}
                button={t('bookOtherServices-button-1')}
                />
                 <BookOtherServicesData
                 className="book-t-card"
                image={bike}
                heading={t('bookOtherServices-heading-2')}
                text={t('bookOtherServices-text-2')}
                capacity={t('bookOtherServices-capacity-2')}
                price={t('bookOtherServices-price-2')}
                button={t('bookOtherServices-button-2')}
                />
                 <BookOtherServicesData
                 className="book-t-card-reverse"
                image={kayakOne}
                heading={t('bookOtherServices-heading-3')}
                text={t('bookOtherServices-text-3')}
                capacity={t('bookOtherServices-capacity-3')}
                price={t('bookOtherServices-price-3')}
                button={t('bookOtherServices-button-3')}
                />

                 <BookOtherServicesData
                 className="book-t-card"
                image={kayakTwo}
                heading={t('bookOtherServices-heading-4')}
                text={t('bookOtherServices-text-4')}
                capacity={t('bookOtherServices-capacity-4')}
                price={t('bookOtherServices-price-4')}
                button={t('bookOtherServices-button-4')}
                />

            </div>
        </div>
    )
}