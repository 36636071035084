import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScrollToId(id) {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === `#${id}`) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [id, location]);
}
