import { useState } from 'react';
import { Hero } from '../components/Hero'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ContactImg from '../assets/blisteringSun.jpg'
import './GalleryStyles.css';

// import useScrollToId from '../hooks/useScrollToId';

import fullLakeView from "../assets/trip-images/media-hora/fullLakeViewVillarrica.jpg"
import puenteLagoVillarrica from "../assets/trip-images/media-hora/puente-lago-villarrica.jpeg"
import vistaVolcanLagoIsla from "../assets/trip-images/media-hora/vista-volcan-lago-isla.jpeg"
import volcanVillarricaDesdeLago from "../assets/trip-images/media-hora/volcan-villarrica-desde-lago.jpeg"
import barco from "../assets/trip-images/una-hora/AEDOBoat.jpg"
import viewFullLake from "../assets/trip-images/una-hora/fullLakeViewVillarrica.jpg"
import volcanVillarricaExpulsandoHumo from "../assets/trip-images/una-hora/volcan-villarrica-expulsando-humo.jpeg"
import volcanVillarrica from "../assets/trip-images/una-hora/volcan-villarrica.jpeg"
import wildlifeVillarricaLake from "../assets/trip-images/una-hora/wildlife-villarrica-lake.jpg"
import barcoAtardecerPiesDelLago from "../assets/trip-images/cuatro-horas/barco-atardecer-pies-del-lago.jpeg"
import barcoFotoNoche from "../assets/trip-images/cuatro-horas/barco-foto-noche.jpeg"
import faroVolcanVillarrica from "../assets/trip-images/cuatro-horas/faro-volcan-villarrica.jpeg"
import islaAillaquillenFaroTuristasFelices from "../assets/trip-images/cuatro-horas/isla-aillaquillen-faro-turistas-felices.jpeg"
import islaAillaquillenTuristaFotoVistaVolcan from "../assets/trip-images/cuatro-horas/isla-aillaquillen-turista-sacando-foto-vista-volcan.jpeg"
import villarricaBirdView from "../assets/trip-images/cuatro-horas/villarrica-lake--island-birdview.jpg"
import volcanVillarricaDesdesIsla from "../assets/trip-images/cuatro-horas/volcan-villarrica-desdes-isla.jpeg"

import turistaFotoUno from "../assets/trip-images/turistas/turista-approximandose-a-isla-aillaquillen.jpeg"
import turistaFotoDos from "../assets/trip-images/turistas/turista-atardecer-barco-lago.jpeg"
import turistaFotoTres from "../assets/trip-images/turistas/turistas-faro-isla-aillaquillen.jpeg"
import turistaFotoCuatro from "../assets/trip-images/turistas/turistas-felices-barco-pies-del-lago.jpeg"
import turistaFotoCinco from "../assets/trip-images/turistas/turistas-felices-faro-isla-aillaquillen.jpeg"
import turistaFotoSeis from "../assets/trip-images/turistas/turistas-felices-puente-atras-en-lago.jpeg"
import turistaFotoSiete from "../assets/trip-images/turistas/turistas-sacando-foto-lago-vista-volcan.jpeg"


const images = [turistaFotoUno,turistaFotoDos,turistaFotoTres,turistaFotoCuatro,turistaFotoCinco,turistaFotoSeis,turistaFotoSiete,fullLakeView, puenteLagoVillarrica,vistaVolcanLagoIsla,volcanVillarricaDesdeLago,barco, viewFullLake, volcanVillarricaExpulsandoHumo,volcanVillarrica,wildlifeVillarricaLake,barcoAtardecerPiesDelLago, barcoFotoNoche,faroVolcanVillarrica,islaAillaquillenFaroTuristasFelices,islaAillaquillenTuristaFotoVistaVolcan,villarricaBirdView,volcanVillarricaDesdesIsla]


export function Gallery(){

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
      setSelectedImage(image);
  };

  const handleClose = () => {
      setSelectedImage(null);
  };

    return(
        <>
          <Navbar/>
            <Hero
            cName="hero-mid"
            heroImg={ContactImg}
            title="Gallery"
            btnClass="hide"
            />
            <div className="gallery-container">
                <div className="gallery">
                    {images.map((image, index) => (
                        <div key={index} className="gallery-item" onClick={() => handleImageClick(image)}>
                            <img src={image} alt={`Gallery item ${index + 1}`} />
                        </div>
                    ))}
                </div>
                {selectedImage && (
                    <div className="modal">
                        <span className="close-btn" onClick={handleClose}>&times;</span>
                        <img src={selectedImage} alt="Expanded view" className="expanded-image" />
                    </div>
                )}
            </div>
            <Footer />
        </>
    )
}