import { Hero } from '../components/Hero'
import Navbar from '../components/Navbar'
import HomeImg from '../assets/fullLakeViewVillarrica.jpg'
import Destination from '../components/Destination'
import Trip from '../components/Trip'
import Footer from '../components/Footer'
import '../i18n'; // Import your i18n configuration
import { useTranslation } from 'react-i18next';
//import useScrollToId from '../hooks/useScrollToId';

export function Home(){
    //useScrollToId('trip-service-options');

    const { t } = useTranslation();

    return(
        <>
        <Navbar/>
        <Hero
        cName="hero"
        heroImg={HomeImg}
        title={t('homepage-h1')}
        text={t('homepage-p')}
        buttonText={t('homepage-cta')}
        url="/services#trip-services"
        btnClass="show"
        />
        <Destination />
        <Trip />
        <Footer/>
        </>
    )
}
