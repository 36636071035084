import { Hero } from '../components/Hero'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AboutUs from '../components/AboutUs'
import AboutImg from '../assets/villarrica-lake-birdview.jpg'

import i18n from '../i18n';  
import { useTranslation } from "react-i18next";

export function About(){
  const { t } = useTranslation();

    return(
        <>
          <Navbar/>
            <Hero
            cName="hero-mid"
            heroImg={AboutImg}
            title={t('about-about')}
            btnClass="hide"
            />
            <AboutUs/>
             <Footer/>

        </>
    )
}
