import '../components/HeroStyles.css'
import {Link} from "react-router-dom";

export function Hero(props){
    return(
        <>
         <div className={props.cName}>
            <img src={props.heroImg} alt="Hero background" />
         </div>

         <div className='hero-text'>
            <h1>{props.title}</h1>
            <p>{props.text}</p>
            <Link to={props.url} className={props.btnClass}>
                {props.buttonText}
            </Link>
         </div>
        </>
    )
}
